/* eslint-disable */
import actions from "./actions";
import { RESETDATA, SWITCH_LANG } from "./actionTypes";
import cookies from "js-cookie";
import i18next from "i18next";

// let currentLanguageCode = cookies.get("i18next");

const initState = {
  lang:  "" ,
  resachState: false
};

export default function appReducer(state = initState, action) {
 
  switch (action.type) {
    case SWITCH_LANG:
      i18next.changeLanguage(action.lang.toLowerCase());
      return {
        ...state,
        lang:action.lang
      };
      case RESETDATA:
          return {
            ...state,
            resachState:action.resachState
          };
    default:
      return state;
  }
}

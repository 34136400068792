import { RESETDATA, SWITCH_LANG } from "./actionTypes";

const actions = {
  noop: () => {}
};

export const switchLang = (lang) => ({
  type: SWITCH_LANG,
  lang
});
export const resetData = (resachState) => ({
  type: RESETDATA,
  resachState
});

export default actions;

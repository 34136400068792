import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Router from "./router";
import "antd/dist/antd.css";

function App() {
  return <Router />;
}

export default App;
